.container-cs {
    display: flex;
    position: relative; // needs to be set for the circles absolute position to work
    min-width: 1230px;
    margin: 80px auto;
    flex: 0 0 1;
  }
  
  * { box-sizing: border-box; } 
  
  // Animated paths/lines
  .line.top {
    stroke-dasharray: 1600;
    stroke-dashoffset: 100;
    opacity: 0;
    animation: dash 3s ease-in 0.5s forwards;
    
    &.topOne { animation-delay: 0s; }
    &.topTwo { animation-delay: 0.2s; }
    &.topThree { animation-delay: 0.4s; }
    &.topFour { animation-delay: 0.6s; }
    &.topFive { animation-delay: 0.8s; } 
  }
  
  @keyframes dash {
    from { 
      stroke-dashoffset: 2000;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 0.15;
    }
  }
  
  .line.bottom {
    stroke-dasharray: 1600;
    stroke-dashoffset: -1000;
    opacity: 0;
    animation: dash-reversed 3s ease-in normal forwards;
    
    &.bottomOne { animation-delay: 0.8s; } 
    &.bottomTwo { animation-delay: 1.0s; } 
    &.bottomThree { animation-delay: 1.2s; } 
  }
  
  @keyframes dash-reversed {
    from {
      stroke-dashoffset: -1000;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 0.2;
    }
  }
  
  // Animated blocks/boxes
  .block {
    stroke-dasharray: 1600;
    stroke-dashoffset: 100;
    opacity: 0;
    animation: block-animation 1.5s ease-in-out 0.5s forwards;
    
    &.topOne { animation-delay: 0s; }
    &.topTwo { animation-delay: 0.2s; }
    &.topThree { animation-delay: 0.4s; }
    &.topFour { animation-delay: 0.6s; }
    &.topFive { animation-delay: 0.8s; } 
    &.bottomOne { animation-delay: 2s; } 
    &.bottomTwo { animation-delay: 2.25s; } 
    &.bottomThree { animation-delay: 2.5s; } 
  }
  
  @keyframes block-animation {
    from { 
      transform: translate(0,20px);
      opacity: 0;
    }
    to {
      transform: translate(0,0);
      opacity: 1;
    }
  }
  
  // Animated circles
  
  .container-c {
    display: flex;
    max-width: 1230px;
    margin: 80px auto;
    flex: 0 0 1;
  }
  
  // Animated paths/lines
  .line.top {
    stroke-dasharray: 1600;
    stroke-dashoffset: 100;
    opacity: 0;
    animation: dash 3s ease-in 0.5s forwards;
    
    &.topOne { animation-delay: 0s; }
    &.topTwo { animation-delay: 0.2s; }
    &.topThree { animation-delay: 0.4s; }
    &.topFour { animation-delay: 0.6s; }
    &.topFive { animation-delay: 0.8s; } 
  }
  
  @keyframes dash {
    from { 
      stroke-dashoffset: 2000;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 0.15;
    }
  }
  
  .line.bottom {
    stroke-dasharray: 1600;
    stroke-dashoffset: -1000;
    opacity: 0;
    animation: dash-reversed 3s ease-in normal forwards;
    
    &.bottomOne { animation-delay: 0.8s; } 
    &.bottomTwo { animation-delay: 1.0s; } 
    &.bottomThree { animation-delay: 1.2s; } 
  }
  
  @keyframes dash-reversed {
    from {
      stroke-dashoffset: -1000;
      opacity: 0;
    }
    to {
      stroke-dashoffset: 0;
      opacity: 0.2;
    }
  }
  
  // Animated circles
  .circles-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 225px;
  }
  
  .circles {
    opacity: 0;
    display: block;
    position: absolute;
    height: 230px;
    width: 230px;
    margin: auto;
    border-radius: 50%;
    top: 170%;
    background: rgba(0, 74, 171, 0.1);
    border: 1px solid rgba(101, 161, 226, 0.17);
    animation: circle-pulse 6s linear infinite forwards;
    mix-blend-mode: multiply;
    
    &.circleOne { 
      animation: circle-entry 1.5s cubic-bezier(0.34, 1.56, 0.64, 1) 1 forwards;
      animation-delay: 1.35s;
      background: #0051AA;
      z-index: 5;
    }
    &.circleTwo { animation-delay: 2s;  }
    &.circleThree { animation-delay: 4s; }
    &.circleFour { animation-delay: 6s; }
  }
  
  @keyframes circle-pulse {
    from { 
      transform: scale(1);
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: scale(1.5);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
  
  @keyframes circle-entry {
    from { 
      transform: scale(0);
      opacity: 0.5;
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .circles-container .logo { 
    display: flex;
    color: white;
    font-family: sans-serif;
    position: relative;
    z-index: 6;
    animation: block-animation 1.5s cubic-bezier(0.34, 1.56, 0.64, 1) 2.5s forwards;
    top: 156px;
  } 
  
  // Animated dots 
  
  .dots {
    stroke-linecap:round;
    stroke-dasharray: 0.5 200;
    animation: dots 5s linear infinite forwards, fade-in 3s ease-in 1 forwards;
    stroke: red;
    opacity: 0;
    
    &.dotsOne { animation-delay: 1.1s; }
    &.dotsTwo { animation-delay: 4s; }
    &.dotsThree { animation-delay: 2.8s; }
    &.dotsFour { animation-delay: 2s; }
    &.dotsFive { animation-delay: 4.9s; }
    
    
    &.bottom.dotsOne { 
      animation: dots-reversed 3.5s linear infinite forwards, fade-in 3s ease-in 1 forwards;
      animation-delay: 3s;
    }
    &.bottom.dotsTwo { 
      animation: dots-reversed 3.5s linear infinite forwards, fade-in 3s ease-in 1 forwards;
      animation-delay: 3s;
    }
    &.bottom.dotsThree { 
      animation: dots-reversed 3.5s linear infinite forwards, fade-in 3s ease-in 1 forwards;
      animation-delay: 5s;
    }
  }
  
  @keyframes dots {
     to {
      stroke-dashoffset: -200;
     }
  }
  @keyframes dots-reversed {
     from {
      stroke-dashoffset: -200;
     }
  }
  
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.66;
    }
  }