.App-headers {
  padding-top: 20px;
  height: 100vh;
  display: inline !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}

.cropped-image {
  height: 370px;
  width: 600px;
}

.cropped-image-container {
  display: flex;
  flex-direction: column;
}

._coverImage-holder {
  padding: 5px 10px;
  background-color: #efefef;
  border: 1px solid black;
  cursor: pointer;
  color: #000;
  margin-bottom: 20px;
}

.containers {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top: 25px; */
}

.crop-container {
  height: 370px;
  width: 600px;
}

#app {
  height: 100%;
}

#navbar {
  position: fixed;
  margin: 0;
  /* top: -50px; */
  /* width: 100%; */
  /* display: none; */
  transition: top 0.3s;
}

/* #navbar a {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  font-size: 17px;
} */

.comming {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/bridge.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  height: 900px;
  margin: 0;
}

.comming h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 298px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-sky {
  color: #113b57;
}

.two {
  width: 47% !important;
  float: left;
  padding: 0 10px;
  display: table;
}

.test01 {
  margin-top: 100px !important;
}

.title {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  position: relative;
}

.title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 4px;
  margin: 0 auto;
  background: #109cd8;
  content: "";
  z-index: 1;
}

.title-c {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  text-align: center;
  position: relative;
}

.title-c:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50px;
  height: 4px;
  margin: 0 auto;
  background: #109cd8;
  content: "";
  z-index: 1;
}

.cl-button {
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  transition-duration: 15s;
  transition-timing-function: step-start;
  transform-origin: 0 0;
}

#mobile {
  width: 15% !important;
}

.cl-button.-big {
  min-width: 235px;
  text-align: center;
  padding: 1rem 2.5rem;
  line-height: 1.78;
  font-size: 1.125rem;
  transition-duration: 10s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
}

.cl-button.-small {
  text-align: center;
  padding: .5rem;
  line-height: 1.78;
  font-size: 1.125rem;
}

.cl-button.-primary {
  /* background-color: #65c0e7; */
  border-color: transparent;
  color: #fff;
  background-image: linear-gradient(45deg, #65c0e7, #0089eb, #65c0e7);
  background-size: 200%;
  transition: background-position 300ms, transform 300ms, box-shadow 300ms;
  border-radius: 7px;
}

.cl-button.-primary:hover {
  /* background-image: linear-gradient(to right, #65c0e7, #0089eb); */
  background-position: 100% 0;
  transform: translate(2px, -4px);
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.4);
}

.cl-button.-secondary {
  background-color: #fff;
  border-color: #65c0e7;
  color: #65c0e7;
}

.cl-button.-secondary:hover {
  background-color: #0089eb;
  color: #f1f1f1;
}

.shadow-sl {
  box-shadow: 20px 40px 50px rgb(0 0 0 / 40%);

}

.shadow-sl:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
}

.lines-title {
  position: absolute;
  top: -220px;
  right: -130px;
  z-index: -1;
}

/****************** header *******************/
.header .nav-links li a {
  color: #000;
}

.header .nav-links li a:hover {
  color: #0a8eeb;
}

/* end header */
/******************** Home *************************/
.react-multi-carousel-list {
  height: auto;
  margin-bottom: 50px;
  padding: 5px 0;
}

.indust_title {
  height: 600px;
}

.home {
  background-color: #000 !important;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  padding: 0;
}

.intro {
  position: relative;
  height: 100vh;
  text-align: center;
  color: #fff;
}

.image-overlay {
  display: block;
  position: absolute;
  bottom: 0;
  opacity: 0.3;
  background-color: #000;
  width: 100%;
  height: 100%;
  top: 0;
}

.firstimg {
  width: 500px;
  float: left;
  margin-left: 50px;
}

.secondimg {
  margin-top: -177px;
  width: 359px;
  float: center;
  margin-left: 190px;
}

/*********************** second blog home  ***********************/
.company {
  padding: 100px 0;
  margin: 20px 0;
  background-image: url('https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/who-we-are.png') !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}

/***********************  ***********************/

/*********************** language ***********************/

.language {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/Our-Service-BG.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background-color: #020d20;
  position: relative;
  padding-top: 100px;
  /* height: 567px; */
}

/*********************** End language ***********************/

/*********************** Company  Devlopment ***********************/
.company_d {
  background-image: url('https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/bg-3.png');
  background-position: center left -20em;
  background-repeat: no-repeat;
  padding: 100px 0;
}

.company_d .circl {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 13px;
  display: inline-block;
}

.company_border:hover {
  border: 3px solid #afcde1;
  border-radius: 50px 0;
  padding: 10px;
  background-image: linear-gradient(to bottom, #fbfbfb, #5ab4f5);
  box-shadow: 0 0 45px rgb(0 0 0 / 5%);
}

.company_border h4:hover {
  color: #000;
}

/******************** End Company  Devlopment *********************/


/********************** Services Process we Perfome **********************/
.h_service {
  padding: 100px 0;
  width: 100%;
  background: url('https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/service-bg.png') no-repeat top -100px center/100% auto;
}

.main_circle {
  width: 389px;
  position: relative;
  height: 389px;
  margin: 49px auto 49px 22px;
  background: #65C0E7;
  border: 25px solid #98cee6;
  border-radius: 50%;
  text-align: center;
}

.main_circle::after {
  width: 430px;
  height: 430px;
  position: absolute;
  top: -45px;
  left: -44px;
  background: #b3d7e7;
  content: '';
  border-radius: 50%;
  z-index: -1;
}

.blue_cl {
  color: #0e82c3 !important;
}

.main_cirvc_crc {
  display: table-cell;
  height: 350px;
  width: 100%;
  vertical-align: middle;
  text-decoration: none;
}

.main_circle h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  text-align: center;
}

.main_circle ul {
  padding-left: 0;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 15px;
}

.main_circle ul li {
  list-style: none;
}

.bottom_section {
  text-align: center;
  width: 100%;
  float: none;
  cursor: pointer;
}

.bottom_section::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
}

.service_icon {
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 7%);
  border-radius: 50%;
  margin: 0 auto;
  border: 2px solid #fff;
  transition: .6s;
  -webkit-transition: .6s;
  -moz-transition: .6s;
  -o-transition: .6s;
  -ms-transition: .6s;
}

.service_title {
  font-size: 18px;
  line-height: 24px;
  color: #247ad6;
  font-weight: 600;
  position: relative;
}

.left-panel,
.right-panel {
  margin-top: 258px;
}

.left-panel {
  padding-right: 20px;
  text-align: right;
}

.left-panel p {
  text-align: right;
}

/* ul li {
  list-style: none;
} */

.service_inner {
  width: 100%;
  float: left;
}

.service_inner li {
  float: left;
  width: 100%;
  margin-bottom: 50px;
  cursor: pointer;
  position: relative;
}

.service_inner li::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
}

.left-panel ul li:nth-child(2) {
  margin-left: -60px;
}

.right-panel ul li:nth-child(2) {
  margin-left: 60px;
}

/********************** End Services Process we Perfome **********************/

/********************** Industires Website **********************/
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #005eb8 !important;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 30%) !important;
  color: #fff !important;
  padding: 16px 40px 16px 16px !important;
  text-decoration: none !important;
  transform: translateX(20px) !important;
  width: calc(100% + 20px) !important;
  padding-left: 50px !important;
  border-radius: 0%;
}

.nav-link {
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: 600;
  height: 100%;
  padding: 16px 0 16px 34px;
  width: 100%;
  text-align: left;
}

.conta {
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 580px;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  /* opacity: 0; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 93%;
  background-color: #ffffff78;
  padding-bottom: 5px;
}

/* .container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
} */

.text {
  /* background-color: #04AA6D; */
  color: #000;
  /* font-size: 16px; */
  padding: 16px 3px;
}

/********************** End Industires Website **********************/


/********************** Our Partners **********************/
.partner {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/partner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background-color: #020d20;
  opacity: 1;
  position: relative;
  padding: 50px 0;
  margin-top: 50px;
  height: auto;
}

/********************** End Our Partners **********************/

/* ********************** Home form ********************** */
.heading {
  font-size: 2.5rem;
  font-weight: 900;
}

.col-form-label {
  color: #000;
}

.custom-select:active,
.custom-select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

#message {
  resize: vertical;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

/* ********************** End home form ********************** */

/********************** Why Us **********************/
.whyus {
  padding: 50px 0;

}

/********************** experience **********************/
.experience {
  padding: 100px 0;
}

/********************** //squares wrapper **********************/
/* .squares-wrapper {
  background: #4e54c8;
  background: linear-gradient(to left, #3182d5, #292a38);
  width: 100%;
  height: 100%;
}

.squares {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.squares li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}


.quote {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100vw;
  z-index: 1;
} */

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* .type {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typewriter {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid #212121;
  animation: cursor 1s step-start infinite,
    text 5s steps(18) alternate infinite;
} */

/* @keyframes cursor {

  0%,
  100% {
    border-color: #212121;
  }
} */

/* @keyframes text {
  0% {
    width: 0;
  }

  100% {
    width: 65%;
  }
} */


/* .cm-content {
  position: relative;
  z-index: 2;
  width: 400px;
  text-align: center;
  font-size: 28px;
  padding: 30px 40px;
  color: #fff;
  background-image: linear-gradient(to right, #0089eb, #65c0e7);
  margin: -175px auto 0 auto;
  border: 15px double #fff;
  transition-duration: 0.5s;
  transition-timing-function: step-start;
  transform-origin: 0 0;
}

.cm-content p {
  background-image: linear-gradient(to right, #0089eb, #65c0e7);
  margin: 0;
  transition-duration: 0.5s;
  transition-timing-function: step-start;
  transform-origin: 0 0;
}

.cm-content:hover {
  background-image: linear-gradient(to right, #65c0e7, #0089eb);
} */


/*** home page Sections second blog***/
.section-xs {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-sm {
  padding: 30px 0;
}

.section-sm.section-first {
  padding-top: 60px;
}

.section-sm.section-last {
  padding-bottom: 60px;
}

.section-60,
.section-md,
.section-lg,
.section-xl {
  padding: 60px 0;
}

/* .fadeInRight {
  visibility: visible;
  animation-name: fadeInRight;
}

.box-icon-modern-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding-top: 36px;
  padding-bottom: 38px;
}

.box-icon-modern {
  text-align: center;
  max-width: 370px;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 66px 15px 50px;
  background: #ffffff;
  box-shadow: 0 0 13px -4px rgb(0 0 0 / 17%);
  transition: all .3s ease;
}

.box-icon-modern-2 {
  padding-top: 42px;
  padding-bottom: 35px;
}

.box-icon-modern-icon {
  position: relative;
  font-size: 47px;
  line-height: 1;
  color: inherit;
  z-index: 1;
}

.box-icon-modern-2 .box-icon-modern-decor {
  margin-top: 18px;
  margin-bottom: 15px;
}

.box-icon-modern-decor {
  position: relative;
  max-width: 126px;
  width: 100px;
  height: 2px;
  margin: 20px auto 22px;
}

.box-icon-modern-decor::before {
  position: absolute;
  content: '';
  height: 100%;
  left: 15px;
  right: 15px;
  background: #d7d7d7;
  transition: all .3s ease;
}

.box-icon-modern-decor::after {
  position: absolute;
  content: '';
  height: 100%;
  left: 50%;
  right: 50%;
  background: #a3b8ff;
  transition: all .3s ease;
} */

.parallax-container {
  position: relative;
  overflow: hidden;
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/parallax-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/************************ Counter ************************/

/* .counter-modern {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  min-height: 220px;
  padding: 20px;
  z-index: 1;
} */

/* .counter-modern::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 6px solid #ebebeb;
  transition: all .3s ease;
  z-index: -1;
}

.counter-modern-2 .counter-modern-decor {
  background: #6689ff;
} */

.counter-modern-number {
  font-size: 60px;
  line-height: 0.6;
  letter-spacing: .025em;
  text-transform: uppercase;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.counter-modern-number>* {
  display: inline-block;
}

/* .counter-modern-decor {
  width: 100%;
  height: 2px;
  margin: 16px 0 16px auto;
  background: #6689ff;
  transition: all .3s ease;
} */

.counter-modern-title {
  display: inline-block;
  font-weight: 300;
  letter-spacing: .05em;
}

.text-width-extra-small {
  max-width: 320px;
}

.title-decoration-lines-left {
  position: relative;
  padding-left: 26px;
}

.title-decoration-lines-left::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background: #d7d7d7;
}

.text-gray-500 {
  color: #9b9b9b;
}

.text-img {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 320px;
  line-height: 85%;
  font-weight: 600;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #000;
  letter-spacing: -.06em;
  padding-right: 10px;
  height: 270px;
}

.text-img:hover {
  color: #9b9b9b;
}

.counter-amy {
  text-align: center;
  padding: 30px 10px;
}

.counter-amy-number {
  font-size: 110px;
  line-height: 0.8;
  font-weight: 300;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.counter-amy-title {
  font-weight: 400;
  letter-spacing: .1em;
  color: #9b9b9b;
}

.counter-amy-number .symbol {
  font-size: 70px;
  letter-spacing: 0;
  margin-left: -8px;
}

.border-creative {
  overflow: hidden;
}

.border-creative>div::before,
.border-creative>div::after {
  position: absolute;
  content: '';
  background: #d1d1d1;
}

.border-creative>div::before {
  height: 1px;
  left: 26px;
  right: 26px;
  bottom: -1px;
}

.border-creative>div::after {
  width: 1px;
  top: 26px;
  right: -1px;
  bottom: 26px;
}

.border-2-column>div {
  padding: 0;
  border-top: 1px solid #e1e1e1;
}

.border-2-column>div:nth-child(1) {
  border-top: none;
}

.border-2-column>div:nth-child(even) {
  border-left: 1px solid #e1e1e1;
}

.border-2-column>div:nth-child(even) .counter-amy {
  padding-right: 0;
}

.border-2-column>div:nth-child(odd) .counter-amy {
  padding-left: 0;
}

.clients-classic {
  position: relative;
  display: block;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  transition: all .3s ease-in-out;
}

.clients-classic img:hover {
  opacity: 1.0;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.clients-classic::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid transparent;
  transition: all .3s ease;
  will-change: transform;
}

.clients-classic img {
  width: 100%;
  opacity: .5;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

#particles-js {
  position: absolute;
  min-width: 280px;
  min-height: 280px;
  top: 0;
  left: 0;
}


/********************** Testimonial **********************/
.carousel-root {
  /* width: 64% !important; */
  margin: auto !important;
}

.team {
  background-color: #5072a7;
  padding: 50px 0;
  margin: 50px 0;
}

.carousel .slide img {
  width: 200px !important;
  text-align: center;
  display: block;
  margin: auto;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

.nav-tabs .nav-link.active {
  background-color: #005eb8 !important;
  color: #000 !important;
}

/* Footer */
.form-control {
  background: #212529;
  border-color: #545454;
}

.form-control:focus {
  background: #212529;
}

footer {
  background: #212529;
}

.demo a {
  text-decoration: none;
}

.test {
  width: auto !important;
  display: inline-block !important;
  pointer-events: none !important;
  height: 130px !important;
  object-fit: cover !important;
}

.section-title .sm-title {
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 12px;
  padding-bottom: 12px;
  color: #2197ce;
  z-index: 9;
}

.section-title .sm-title:before {
  position: absolute;
  content: '';
  height: 2px;
  width: 80px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #dedede;
}

.section-title .sm-title:after {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: #2197ce;
  right: 0px;
  left: auto;
  bottom: -3px;
  animation: pulsex 5s ease infinite alternate;
}

/********************************* About *********************************/
.block1 {
  padding: 0;
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abt-sect1.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #020d20;
}

.block2 {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/block2.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #0c0b09;
}

.about {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/about_us.png");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.services {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/services.png");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.benefits-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background: #fff;
  height: 100%;
  border-radius: 2px;
}

/* ********************************* About Us End ********************************* */

/* ********************************* Blog ********************************* */
.carousel .carousel-status {
  padding: 20px !important;
}

.blog-img {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1724314274/website/blog.png");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-bottom: 30px;
}


.blog {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.tag {
  background-image: linear-gradient(45deg, #65c0e7, #0089eb, #65c0e7);
  font-size: 11px;
  border-radius: 0.25rem;
}

#success {
  display: none;
}

#fail {
  display: none;
}

.paginationDiv {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center !important;
}

.paginationDiv ul {
  text-align: center;
  width: 100%;
}

.paginationDiv ul li {
  display: inline;
  margin: 0px 5px;
}

.paginationDiv ul li a {
  width: 44px;
  height: 44px;
  border: 1px solid #2a2a2a !important;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  font-weight: 500;
  color: #2a2a2a;
}

.paginationDiv ul li a:hover,
.paginationDiv ul li.active a {
  background-image: linear-gradient(to right, #0089eb, #65c0e7) !important;
  color: #fff;
}

.scroll {
  white-space: nowrap;
  width: 750px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-post-header-info {
  align-items: center;
  border-top: 2px solid #2e475d !important;
  border-bottom: 2px solid #2e475d !important;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1rem;
  padding: 1rem 0;
}

.blog-post-header-authors {
  align-items: center;
  display: grid;
  gap: 0 0.5rem;
  grid-template-columns: max-content max-content 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    '-primary primary-author'
    '-secondary secondary-author';
}

.soc-share {
  position: sticky;
  top: 80px;
  left: 0;
  width: 50px;
  border: 1px solid var(--c-silver);
  border-radius: var(--bdrs);
  background-color: var(--c-gray);
  pointer-events: all;
}

/* Delete pop-up */
.modal-confirm {
  color: #636363;
  width: 400px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-confirm .modal-body {
  color: #999;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.modal-confirm .modal-footer a {
  color: #999;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}

.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}

.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

/* Delete pop-up */

/* ********************************* Blog End ********************************* */


/* ********************************* Services ********************************* */

.feature-item {
  max-width: 348px;
  min-height: 620px;
  padding: 25px;
  margin-top: 40px;
  background: #ecf2fe;
  border-radius: 10px;
  position: relative;
}

.features-style-three .feature-item {
  min-height: 540px;
}

.feature-item:before,
.feature-item:after {
  position: absolute;
  top: -20px;
  left: 30px;
  right: 30px;
  height: 20px;
  background: #eff4fe;
  content: "";
  border-radius: 10px 10px 0 0;
  z-index: 1;
}

.feature-item .emizentech-icon {
  padding-bottom: 30px;
  text-align: center;
}

.feature-item:after {
  top: -40px;
  left: 50px;
  right: 50px;
  background: #f7f9fd;
}

.color-three {
  background: #fde2f9;
}

.color-three:after {
  background: #fdf6fc;
}

.color-three:before {
  background: #fff2fe;
}

.color-four {
  background: #e3fcf4;
}

.color-four:before {
  background: #ebfbf6;
}

.color-four:after {
  background: #f5fffc;
}

.color-six {
  background: #e6e5ff;
}

.color-six:before {
  background: #f2f1ff;
}

.color-six:after {
  background: #f6f6ff;
}

.get_started_box {
  background: linear-gradient(99.89deg, #790030 -34.3%, #020041 112.4%);
  border-radius: 8px;
  padding: 60px 50px;
}

.get_started_box:before {
  content: "";
  position: absolute;
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/get_startrd_bg.png");
  background-size: contain;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top left;
  top: 0;
  left: 0;
}

.get_started_box .mobile_app_title {
  line-height: 47px;
  padding-bottom: 20px;
}

.mobile_app_title {
  font-weight: 600;
  line-height: 55px;
  color: #252525;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.get_started_box .mobile_app_title,
.get_started_section .get_started_box p {
  color: #fff;
  margin: 0;
}

.api-item .emizentech-icon {
  display: table-cell;
  vertical-align: middle;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background-color: #dafdf2;
  border-radius: 8px;
}

.api-info .api-item h4 {
  display: table-cell;
  vertical-align: middle;
  padding: 0 0 0 15px;
  color: #20323a;
  font-size: 15px;
}

/* ********************************* Services End ********************************* */


/* *********************************  Contact Us ********************************* */
.contacts {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/contact-bg.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
}

.contact-box {
  width: clamp(100px, 90%, 1000px);
  margin: 80px 50px;
  display: flex;
  flex-wrap: wrap;
}

.contact-links,
.contact-form-wrapper {
  width: 35%;
  padding: 4% 5%;
}

.contact-links {
  background-color: #1f2e43;
  background:
    radial-gradient(circle at 55% 92%, #426691 0 12%, transparent 12.2%),
    radial-gradient(circle at 94% 72%, #426691 0 10%, transparent 10.2%),
    radial-gradient(circle at 20% max(78%, 350px), #263a53 0 7%, transparent 7.2%),
    radial-gradient(circle at 0% 0%, #263a53 0 40%, transparent 40.2%),
    #1f2e43;
  border-radius: 10px 0 0 10px;
}

.contact-form-wrapper {
  background-color: #ffffff8f;
  border-radius: 0 10px 10px 0;
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding-top: 50px; */
}

.link {
  margin: 10px;
  cursor: pointer;
}

.form-item {
  position: relative;
}

label,
input,
textarea {
  font-family: 'Poppins', sans-serif;
}

.labe {
  position: absolute;
  top: 10px;
  left: 2%;
  color: #999;
  font-size: clamp(14px, 1.5vw, 18px);
  pointer-events: none;
  user-select: none;
}

.full {
  display: contents;
}

input,
textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}

input:focus+label,
input:valid+label,
textarea:focus+label,
textarea:valid+label {
  font-size: clamp(13px, 1.3vw, 16px);
  color: #777;
  top: -20px;
  transition: all .225s ease;
}

.submit-btn {
  background-color: #3d48e0;
  filter: drop-shadow(2px 2px 3px #0003);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: clamp(16px, 1.6vw, 18px);
  display: block;
  padding: 12px 20px;
  margin: 2px auto;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}

.submit-btn:hover {
  transform: scale(1.1, 1.1);
}

.submit-btn:active {
  transform: scale(1.1, 1.1);
  filter: sepia(0.5);
}

/* *********************************  Contact Us End ********************************* */

/* ****************************** Carrer ***************************************** */

.career {
  height: 567px;
  background-image: url('https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/abouts/career-bg.png');
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
  color: #fff;
}

.head {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  text-align: left;
  position: relative;
}

.head:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 10;
  width: 50px;
  height: 4px;
  margin: 0 auto;
  background: #109cd8;
  content: "";
  z-index: 1;
}

.message {
  font-size: 1em;
  color: #36a936;
}

.error-message {
  font-size: 1em;
  color: #B90B0B;
}

/******************************* Carrer End ******************************************/

/******************************* Portfolio  ******************************************/
.portfolio {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/portfolio.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background-color: #020d20;
  position: relative;
  padding-top: 100px;
  height: 567px;
}

.ped {
  padding-top: 150px;
}

/******************************* End Portfolio  ******************************************/

/******************************* Privet Policy  ******************************************/
.privet_policy {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/privet_policy.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-top: 100px;
  height: 567px;
}

/******************************* End Privet Policy  ******************************************/

/******************************* Terms of Use  ******************************************/
.Terms_of_use {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/privet_policy.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-top: 100px;
  height: 567px;
}

/******************************* End Terms of Use  ******************************************/

/*********************** Up side Scrolling and hide ********************/

.sky_wrap {
  background-color: #ebf3fe;
  padding: 55px 20px;
  margin: 0px auto;
  border-radius: 0px 60px 0px 60px;
}

.zoom-ball {
  position: absolute;
  left: 0;
  top: 68%;
  height: 10%;
  width: 10%;
  max-width: 1920px;
  margin: 0 auto;
}

.zoom-ball .icon {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
}

.zoom-ball .icon-1 {
  left: -30px;
  top: 60%;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

/********************* Event ************************/
.events li {
  display: flex;
  color: #000;
}

.events time {
  position: relative;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 150px;
  transform: translateX(50%);
  border-radius: 50%;
  background: #000;
  border: 1px #ccc solid;
  width: 25px;
  height: 25px;
}

.events .link {
  position: relative;
}

.events .link::before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 517px;
  border: 1px #000 solid;
  transform: translateX(50%);
}

.events {
  width: 100%;
}

.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}

/* ********************** Effact ********************** */
.btn-change2 {
  background: #31708f;
  border: 0px;
  border-radius: 7px;
  color: #fff;
  padding: 10px 15px;
  box-shadow: 0 0 1px #ccc;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
  box-shadow: 0px 0px 0 100px lightseagreen inset;
}

.btn-change2::before {
  width: 100%;
  height: 0;
  transform: translate(-50%, -50%)rotate(45deg);
}

.btn-change2:hover {
  box-shadow: 0px 0px 0 0px lightseagreen inset;
  transform: scale(1);
  color: #fff;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
  top: 170%;
  display: block;
}

.small {
  width: 200px;
  height: 200px;
  right: 170px;
  top: 69%
}

.medium {
  width: 400px;
  height: 400px;
  right: 200px;
  top: 27%;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
  top: 60%;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
  display: inline;
  top: 35%;
}

.xxlarge {
  width: 900px;
  height: 1000px;
  left: 900px;
  bottom: -500px;
  top: 293%;
  overflow: hidden;
  display: block;
}

.shade1 {
  opacity: 0.2;
}

.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

.main-timeline {
  position: relative
}

.main-timeline:before {
  content: "";
  width: 5px;
  height: 100%;
  border-radius: 20px;
  margin: 0 auto;
  background: #242922;
  position: absolute;
  top: 0;
  left: 0;
  right: 0
}

.main-timeline .timeline {
  display: table;
  margin-bottom: 50px;
  position: relative
}

.main-timeline .timeline:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  background: #ec496e;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%)
}

.main-timeline .timeline-icon {
  display: table;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 3px solid #ec496e;
  padding: 13px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 32%;
  transform: translateY(-50%)
}

.main-timeline .timeline-icon i {
  display: block;
  border-radius: 50%;
  background: #ec496e;
  font-size: 64px;
  color: #fff;
  line-height: 100px;
  z-index: 1;
  position: absolute;
  width: 80%;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.5);
}

.main-timeline .timeline-icon:after,
.main-timeline .timeline-icon:before {
  content: "";
  width: 100px;
  height: 4px;
  background: #ec496e;
  position: absolute;
  top: 50%;
  right: -100px;
  transform: translateY(-50%)
}

.main-timeline .timeline-icon:after {
  width: 70px;
  height: 50px;
  background: #2da2e9;
  top: 89px;
  right: -30px
}

.main-timeline .timeline-content {
  width: 50%;
  padding: 0 50px;
  margin: 52px -6px 0 -6px;
  float: right;
  position: relative;
  background-color: #f5f5f5;
}

.main-timeline .timeline-content:before {
  content: "";
  width: 80%;
  height: 100%;
  border: 3px solid #ec496e;
  border-top: none;
  border-right: none;
  position: absolute;
  bottom: -46px;
  left: 35px
}

.main-timeline .timeline-content:after {
  content: "";
  width: 37px;
  height: 3px;
  background: #ec496e;
  position: absolute;
  top: 44px;
  left: 0
}

.main-timeline .description {
  display: inline-block;
  font-size: 16px;
  color: #404040;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0
}

.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: 32%
}

.main-timeline .timeline:nth-child(even) .timeline-icon:before {
  right: auto;
  left: -100px
}

.main-timeline .timeline:nth-child(even) .timeline-icon:after {
  right: auto;
  left: -30px
}

.main-timeline .timeline:nth-child(even) .timeline-content {
  float: left
}

.main-timeline .timeline:nth-child(even) .timeline-content:before {
  left: auto;
  right: 35px;
  transform: rotateY(180deg)
}

.main-timeline .timeline:nth-child(even) .timeline-content:after {
  left: auto;
  right: 0
}

.main-timeline .timeline-icon i:hover {
  background: #151515;
}

.main-timeline .timeline:nth-child(2n) .timeline-content:after,
.main-timeline .timeline:nth-child(2n) .timeline-icon i,
.main-timeline .timeline:nth-child(2n) .timeline-icon:before,
.main-timeline .timeline:nth-child(2n):before {
  background: #f9850f;
}

.main-timeline .timeline:nth-child(2n) .timeline-icon i:hover {
  background: #151515;
}

.main-timeline .timeline:nth-child(2n) .timeline-icon {
  border-color: #f9850f
}

.main-timeline .timeline:nth-child(2n) .timeline-content:before {
  border-left-color: #f9850f;
  border-bottom-color: #f9850f
}

.main-timeline .timeline:nth-child(3n) .timeline-content:after,
.main-timeline .timeline:nth-child(3n) .timeline-icon i,
.main-timeline .timeline:nth-child(3n) .timeline-icon:before,
.main-timeline .timeline:nth-child(3n):before {
  background: #8fb800
}

.main-timeline .timeline:nth-child(3n) .timeline-icon i:hover {
  background: #151515
}

.main-timeline .timeline:nth-child(3n) .timeline-icon {
  border-color: #8fb800
}

.main-timeline .timeline:nth-child(3n) .timeline-content:before {
  border-left-color: #8fb800;
  border-bottom-color: #8fb800
}

.main-timeline .timeline:nth-child(4n) .timeline-content:after,
.main-timeline .timeline:nth-child(4n) .timeline-icon i,
.main-timeline .timeline:nth-child(4n) .timeline-icon:before,
.main-timeline .timeline:nth-child(4n):before {
  background: #2fcea5
}

.main-timeline .timeline:nth-child(4n) .timeline-icon {
  border-color: #2fcea5
}

.main-timeline .timeline:nth-child(4n) .timeline-content:before {
  border-left-color: #2fcea5;
  border-bottom-color: #2fcea5
}

/***************** Mobile Application ***********************/

.mobile {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/mobile_app/Mobile_app_banner.jpg");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.three {
  width: 31.33% !important;
  float: left;
  padding: 0 10px;
  display: table;
}

/***************** Web Development Application ***********************/
.web_dev {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/web_dev/Web-Development.jpg");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

/***************** CSD Development Application ***********************/
.csd {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/Banner.png");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.csd_serv {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/csd/banner_2.png");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 50px;
  padding-bottom: 50px;
  height: auto;
  align-items: center;
}

/***************** Hybrid Development Application ***********************/
.hybrid {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/hybrid_banner.jpg");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.bounce-1 {
  animation: bounce 2s infinite;
  animation-timing-function: linear;
  z-index: 1;
  margin-top: 15%;
}

.box {
  align-self: flex-end;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
}

/***************** Ecommerce Development Application ***********************/
.ecomm {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/ecomm/eCommerce.png");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.ecom {
  background-image: linear-gradient(to right, #0691f5, #085f85);
}

/***************** Hire Development Resources Development Application ***********************/
.hire {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/hybrid/hybrid_banner.jpg");
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000;
  padding-top: 100px;
  height: 567px;
}

.hire_back {
  padding-top: 50px;
  padding-bottom: 50pxn;
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/download.png");
  height: auto;
  -webkit-animation: bg-scrolling-reverse 10s infinite;
  -moz-animation: bg-scrolling-reverse 10s infinite;
  -o-animation: bg-scrolling-reverse 10s infinite;
  animation: bg-scrolling-reverse 10s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

/* ********************** Animations ********************** */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: center
  }
}

@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: center
  }
}

@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: center
  }
}

@keyframes bg-scrolling-reverse {
  100% {
    background-position: center
  }
}

@-webkit-keyframes bg-scrolling {
  0% {
    background-position: center
  }
}

@-moz-keyframes bg-scrolling {
  0% {
    background-position: center
  }
}

@-o-keyframes bg-scrolling {
  0% {
    background-position: center
  }
}

@keyframes bg-scrolling {
  0% {
    background-position: center
  }
}

/***************** portfolio page ***************/
.pro_web {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/web-development.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background-color: #020d20;
  position: relative;
  padding-top: 100px;
  height: 567px;
}


/* ********************** Mobile development Portfolio ********************** */
.mobile_web {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/bg_02-1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #020d20;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
}

.smartphone {
  position: relative;
  width: 375px;
  max-width: 100%;
  height: 800px;
  margin: 0;
  margin-left: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

.phone img {
  width: 22rem;
  height: 44rem;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-touch-callout: none;
}

.single-welcome-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 20px 0;
}

.welcome-slider-wrapper .logo img {
  margin: 0 auto;
}

.slick-slide img {
  display: block;
}

/* ***************************** Mobile development Portfolio End ***************************** */


/* ************************** Custome Development Portfolio ************************** */
.custome_web {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/custome_portfolio/Bg_n.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background-color: #020d20;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px;
  height: auto;
}

.laptop {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  position: relative;
  width: 760px;
  height: 378px;
  border-radius: 6px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 10px 14px 80px;
  background-color: black;
  text-align: center;
}

/* The keyboard of the laptop */
.laptop::after {
  content: '';
  display: block;
  position: relative;
  margin: 0 auto;
  margin-top: -3px;
  border-radius: 6px;
  justify-self: center;
  border-bottom: 58px solid #030303;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 220px;
}

/* The top of the keyboard */
/* .laptop::before {
  content: '';
  display: block;
  position: absolute;
  width: 149px;
  height: 55px;
  bottom: -156px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  background: #f1f1f1;
  z-index: 1;
} */

/* The screen (or content) of the device */
.laptop .content {
  width: 726px;
  height: 371px;
  overflow: hidden;
  border: none;
}

/* ************************** End Custome Development Portfolio ************************** */


/* ************************** Website Development Portfolio ************************** */
.contain {
  padding-bottom: 50px;
}

/* ************************** End Website Development Portfolio ************************** */


/* **************************  Case Study ************************** */

.casestudy {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/porfolio/web_portfolio/web-development.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background-color: #020d20;
  position: relative;
  padding-top: 100px;
  height: 567px;
}

.caseimg {
  height: 300px !important;
  width: 100%;
}

.caseimg:hover {
  cursor: crosshair;
  opacity: .6;
}

.casetext {
  background-color: #d7dbdd;
}

#casestudy {
  display: none;
}

/* ************************** End Case Study ************************** */

/* ************************** Supply Chain Case Study ************************** */

.supplychain {
  background-image: url("https://res.cloudinary.com/dm1muiikf/image/upload/v1684484853/website/case_study/supply-chain.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-top: 300px;
  height: 800px;
  margin: 0;
}

.text-h4 {
  color: #000;
  font-family: Sumana, serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.28;
  margin: 100px 0 32px;
  position: relative;
  z-index: 1;
  padding-left: 15px;
}

.text-h4::before {
  background-image: linear-gradient(to right, #0089eb, #65c0e7);
  content: "";
  display: block;
  height: 90px;
  left: -34px;
  position: absolute;
  top: -34px;
  width: 90px;
  z-index: -1;
  border-radius: 50%;
}

.steps {
  position: relative;
}

.steps:before {
  top: 125px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #2898c4;
  left: 50%;
  margin-left: -1px;
}

.timelines {
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  padding: 0px;
  list-style-type: none;
}

.timelines .timelines-box {
  position: relative;
  float: left;
  clear: left;
  width: 45%;
  margin: 1em 2.5%;
  list-style-type: none;
  display: block;
  padding: 25px;
  background: #f8f8f8;
  z-index: 15;
}

.timelines .timelines-box .timelines-title {
  color: #2898c4;
  font-weight: 700;
  font-size: 1em;
  float: left;
  /* padding-left: 25px; */
  padding-top: 8px;
}

.timeline-point {
  color: #2898c4;
  font-weight: 700;
  font-size: 1em;
}

.timelines .timelines-box .timelines-details {
  clear: both;
  padding-top: 5px;
}

.timelines .timelines-box .deee {
  position: absolute;
  top: -15px;
  right: 24px;
  font-size: 8em;
  font-weight: 700;
  color: #000;
  opacity: 0.1;
  z-index: -1;
}

/* @media all and (max-width: 600px) {
  .timelines .timelines-box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
} */

.timelines .timelines-box:hover:after {
  background: #2898c4;
}

/* @media all and (min-width: 650px) {
  .timelines .timelines-box:after {
    display: block;
    content: ' ';
    height: 9px;
    width: 9px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -6.55%;
    top: 1.5em;
    border: 2px solid #2898c4;
  }
} */

.timelines .timelines-box:first-child {
  margin-bottom: 2.5em;
}

.timelines .timelines-box:nth-child(3) {
  margin-bottom: 2.5em;
}

.timelines .timelines-box:nth-of-type(1n):before {
  position: absolute;
  top: 15px;
  right: -10px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-left: 10px solid #f8f8f8;
  border-right: 0 solid #f8f8f8;
  border-bottom: 10px solid transparent;
  content: " ";
}

.timelines .timelines-box:nth-of-type(2n) {
  float: right;
  clear: right;
}

.timelines .timelines-box:nth-of-type(2n):before {
  right: auto;
  left: -10px;
  position: absolute;
  top: 15px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-right: 10px solid #f8f8f8;
  border-left: 0 solid #f8f8f8;
  border-bottom: 10px solid transparent;
  content: " ";
}

@media all and (min-width: 600px) {
  .timelines .timelines-box:nth-of-type(2n) {
    margin-top: 3em;
  }
}

.timelines .timelines-box:nth-child(2n):after {
  left: -6.5%;
  right: auto;
}

.timelines-box:hover,
.timelines-box:focus {
  transform: translate(0, -5px);
  -webkit-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
}

.timelines-box:hover,
.timelines-box:focus {
  -webkit-box-shadow: 0px 3px 0px 0px #2aaadd;
  -moz-box-shadow: 0px 3px 0px 0px #2aaadd;
  box-shadow: 0px 3px 0px 0px #2aaadd;
}

.timelines-box {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
}

.steps-area {
  padding: 70px 0;
}

#casestudy {
  display: none;
}

/* ************************** End Supply Chain Case Study ************************** */

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(-3deg);
  }

  70% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#anim:hover {
  animation: shake 500ms ease-in-out forwards;
}

.section-about .skills-charts {
  width: 60%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

#myCanvasContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#myCanvas {
  margin: auto;
  width: 100%;
}

.skills-charts::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

.section-about #myCanvasContainer li a {
  font-size: 30px;
  font-family: font-file-82132;
}

#myCanvasContainer:focus {
  outline: 0;
}

.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease 0.4s;
}

.show {
  visibility: visible;
  opacity: 1;
}

.show_image {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.App {
  font-family: sans-serif;
  background: #1c1d1f;
  color: white;
  height: 100vh;
  box-sizing: border-box;
}

/* Popup style */
.popup-boxx {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.boxxes {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.closes-icons {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}


.dropdown-toggle::after {
  transform: rotate(0deg);
  vertical-align: -0.255px !important;
}

/* New added */
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

/****************** footer *******************/
.content {
  height: 70vh;
}

.footer-99382 {
  padding: 4rem 0 1rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #777;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.footer-99382 h3 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}

.footer-99382 .footer-site-logo {
  font-size: 1.5rem;
  color: #fff;
}

.footer-99382 .nav-links li {
  margin-bottom: 10px;
}

.footer-99382 .nav-links li a {
  color: #999999;
}

.footer-99382 .nav-links li a:hover {
  color: #fff;
}

.footer-99382 .btn.btn-tertiary {
  background-color: #e42c64;
  color: #fff;
  border-radius: 30px;
  border: none;
  padding: 10px 20px;
}

.footer-99382 .social li {
  display: inline-block;
}

.footer-99382 .social li a {
  color: gray;
  padding: 7px;
}

.footer-99382 .social li a:hover {
  color: #fff;
}

.footer-99382 .copyright {
  border-top: 1px solid #595959;
}

.dropdown-toggle {
  color: #000 !important;
}

.__react_modal_image__modal_container {
  z-index: 9999999 !important;
}


/* // navbar.css */
.hamburger {
  /* // removes default border on button element */
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

#h_service_mobile {
  display: none;
}

/* end footer */
/* --------------------------------- Media Query ------------------------------- */

@media only screen and (min-width: 1201px) {
  .main-timeline .timeline-icon:before {
    width: 50px;
    right: -50px
  }

  .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    right: auto;
    left: -50px
  }

  .main-timeline .timeline-content {
    margin-top: 75px
  }

  canvas {
    /* width: 800px; */
    width: 100%;
  }

  .laptop {
    width: 550px !important;
    height: 300px !important;
  }

  .laptop .content {
    width: 520px !important;
    height: 290px !important;
  }

  .laptop::after {
    width: 200px !important;
    height: 40px !important;
    margin: 0px 0 0 150px !important;
  }
}

@media only screen and (max-width: 991px) {
  .cl-button.-big {
    margin-top: 1rem;
    margin-bottom: 3rem;
    min-width: auto;
  }
}

@media only screen and (min-width: 465px) and (max-width: 767px) {
  .card-align {
    height: 67rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .card-align {
    height: 43rem !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1025px) {
  .card-align {
    height: 39rem !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .card-align {
    height: 42rem !important;
  }
}


@media only screen and (max-width: 1025px) and (max-width: 1200) {
  .mobo-menu {
    display: block;
    cursor: pointer;
  }

  canvas {
    width: 700px;
  }

  .company_d {
    margin: auto;
    text-align: center;
  }

  .h_service {
    text-align: center;
    margin: 0 !important;
  }

  .service_inner {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  .left-panel {
    margin: 0;
    text-align: center;
  }

  .left-panel p {
    text-align: center;
  }

  .left-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
  }

  .right-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
  }

  .indust_title {
    /* height: 950px; */
    margin: 0;
  }

  .project {
    text-align: center;
  }

  .middle {
    width: 100%;
    top: 84% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .image {
    width: 512px !important;
    height: 639px !important;
  }

  .indust {
    margin: auto;
    text-align: center;
  }

  .indust_containt {
    padding: 0 !important;
    margin: 0 !important;
    /* display: contents; */
  }

  .indust_containt p {
    padding: 0 !important;
    margin: 0 !important;
  }

  .main_circle {
    width: 400px;
    height: 400px;
  }

  .main_cirvc_crc {
    height: 439px;
  }

  .main_circle::after {
    width: 441px;
    height: 441px;
  }

  .language {
    height: 600px;
  }

  .three {
    width: 47% !important;
  }

  .contact-links,
  .contact-form-wrapper {
    width: 100%;
  }

  .contact-links {
    border-radius: 10px 10px 0 0;
  }

  .contact-form-wrapper {
    border-radius: 0 0 10px 10px;
  }

  .card-align {
    height: 40rem !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .mobo-menu {
    display: block;
    cursor: pointer;
  }

  .company_d {
    margin: auto;
    text-align: center;
  }

  .h_service {
    text-align: center;
    margin: 0 !important;
  }

  #b_mobile {
    display: none !important;
  }

  .service_inner {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  .left-panel {
    margin: 0;
    text-align: center;
  }

  .left-panel p {
    text-align: center;
  }

  .right-panel ul {
    margin-top: 140px;
  }

  .left-panel ul {
    margin-top: 140px;
  }

  .left-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
  }

  .right-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
  }

  .main_circle {
    margin: 0;
    margin-top: 123px;
  }

  .bottom_section {
    margin-top: 100px;
  }

  .indust_title {
    height: 950px;
    margin: 0;
  }

  .project {
    text-align: center;
  }

  .middle {
    width: 100%;
    top: 44% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .image {
    width: 512px !important;
    height: 539px !important;
  }

  .indust {
    margin: auto;
    text-align: center;
  }

  .indust_containt {
    padding: 0 !important;
    margin: 0 !important;
    display: contents;
  }

  .indust_containt p {
    padding: 0 !important;
    margin: 0 !important;
  }

  .main_circle {
    width: 268px;
    height: 268px;
  }

  .main_circle::after {
    display: none;
  }

  .service_inner li {
    margin-bottom: 20px;
  }

  .tab-content {
    padding: 0;
  }

  .indust_title {
    height: 600px;
  }

  .language {
    padding: 0 !important;
    margin: 0;
    height: auto;
  }

  .service_icon {
    width: 66px;
    height: 66px;
  }

  .service_icon svg {
    height: 50px;
  }

  .main_cirvc_crc {
    height: 239px;
  }

  .main_circle::after {
    width: 341px;
    height: 341px;
  }

  .main-timeline {
    padding-top: 50px
  }

  .laptop {
    width: 550px !important;
    height: 300px !important;
  }

  .laptop .content {
    width: 520px !important;
    height: 290px !important;
  }

  .laptop::after {
    width: 200px !important;
    height: 40px !important;
    margin: 0px 0 0 150px !important;
  }

  .main-timeline:before {
    left: 80px;
    right: 0;
    margin: 0
  }

  .phone img {
    width: auto;
    height: 42rem;
  }

  .main-timeline .timeline {
    margin-bottom: 70px
  }

  .main-timeline .timeline:before {
    top: 0;
    left: 83px;
    right: 0;
    margin: 0
  }

  .main-timeline .timeline-icon {
    width: 60px;
    height: 60px;
    line-height: 40px;
    padding: 5px;
    top: 0;
    left: 0
  }

  .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: 0;
    right: auto
  }

  .main-timeline .timeline-icon:before,
  .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    width: 25px;
    left: auto;
    right: -25px
  }

  .main-timeline .timeline-icon:after,
  .main-timeline .timeline:nth-child(even) .timeline-icon:after {
    width: 25px;
    height: 30px;
    top: 44px;
    left: auto;
    right: -5px
  }

  .main-timeline .timeline-icon i {
    font-size: 30px;
    line-height: 45px
  }

  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    width: 100%;
    margin-top: -15px;
    padding-left: 130px;
    padding-right: 5px
  }

  .main-timeline .timeline:nth-child(even) .timeline-content {
    float: right
  }

  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    width: 50%;
    left: 120px
  }

  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    right: auto;
    transform: rotateY(0)
  }

  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(even) .timeline-content:after {
    left: 85px
  }

  .circle {
    display: none;
  }

  .three {
    width: 47% !important;
  }

  canvas {
    width: 400px;
  }

  .rounded {
    width: 340px;
  }

  #case_study {
    display: none;
  }

  #casestudy {
    display: contents;
  }

  .circles-container {
    /* color: #000 !important; */
    top: 74.5% !important;
    /* word-wrap: break-word; */
    /* width: 90px; */
  }

  .circles {
    width: 150px !important;
    height: 150px !important;
  }

  .svg_imge {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media only screen and (min-width: 540px) and (max-width: 768px) {
  #mobile {
    width: 35% !important;
  }

  .footer {
    text-align: center !important;
  }

  .mobo-menu {
    display: block;
    cursor: pointer;
  }

  .company_d {
    margin: auto;
    text-align: center;
    padding: 0;
  }

  .firstimg {
    width: 342px;
    margin-left: 30px;
  }

  .secondimg {
    width: 231px;
    margin-left: 235px;
    margin-top: -96px;
  }

  .language {
    height: 100%;
  }

  .three {
    width: 100% !important;
  }

  .h_service {
    text-align: center;
    margin: 0 !important;
  }

  .service_inner {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  .left-panel {
    margin: 0;
    text-align: center;
  }

  .left-panel p {
    text-align: center;
  }

  .left-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
  }

  .right-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
  }

  .indust_title {
    height: 950px;
    margin: 0;
  }

  .project {
    text-align: center;
  }

  .middle {
    width: 100%;
    top: 44% !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .image {
    width: 512px !important;
    height: 539px !important;
  }

  .indust {
    margin: auto;
    text-align: center;
  }

  .indust_containt {
    padding: 0 !important;
    margin: 0 !important;
    display: contents;
  }

  .indust_containt p {
    padding: 0 !important;
    margin: 0 !important;
  }

  .main_circle {
    width: 300px;
    height: 300px;
  }

  .main_cirvc_crc {
    height: 319px;
  }

  .phone img {
    width: auto;
    height: 42.4rem;
  }

  .main_circle::after {
    width: 341px;
    height: 341px;
  }

  .contact-box {
    width: 95%;
    margin: 8% 5%;
  }

  .mobo-menu {
    display: block;
    cursor: pointer;
  }

  .h_service {
    padding: 60px 0;
    margin: 0;
  }

  .partner {
    height: auto;
    padding: 50px;
  }

  .image {
    height: auto;
  }

  .main_circle {
    margin: 61px 0 61px 62px;
    width: 350px;
    height: 350px;
  }

  .main_circle::after {
    width: 400px;
    height: 400px;
  }

  #industry {
    margin: auto;
  }

  .laptop {
    width: 522px !important;
    height: 300px !important;
  }

  .laptop .content {
    width: 495px !important;
    height: 290px !important;
  }

  canvas {
    width: 360px;
  }

  #home_services {
    display: none;
  }

  #b_page {
    display: none;
  }

  #h_service_mobile {
    display: contents;
  }

  #b_mobile {
    display: block;
  }

  .blog {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  #case_study {
    display: none;
  }

  #casestudy {
    display: contents;
  }

  .circles.circleOne {
    display: none;
  }

  .circles.circleTwo {
    display: none;
  }

  .circles-container .logo {
    color: #000 !important;
  }

  .svg_imge {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .circles-container {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    top: 235px !important;
  }

  .circles {
    width: 80px !important;
    height: 80px !important;
    top: 100% !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 540px) {
  #mobile {
    width: 35% !important;
  }

  #case_study {
    display: none;
  }

  #casestudy {
    display: contents;
  }

  #b_page {
    display: none;
  }

  .circles.circleOne {
    display: none;
  }

  .circles.circleTwo {
    display: none;
  }

  .circles-container .logo {
    color: #000 !important;
  }

  .svg_imge {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .circles-container {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    top: 235px !important;
  }

  .circles {
    width: 80px !important;
    height: 80px !important;
    top: 100% !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 480px) {
  #mobile {
    margin: 0;
    width: 35% !important;
    padding-top: 7px;
  }

  .cl-button.-big {
    padding: 1rem 0.5rem;
  }

  .mobo-menu {
    display: block;
    cursor: pointer;
  }

  .company_d {
    margin: auto;
    text-align: center;
  }

  .h_service {
    text-align: center;
    margin: 0 !important;
  }

  .service_inner {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  .left-panel {
    margin: 0;
    text-align: center;
  }

  .left-panel p {
    text-align: center;
  }

  .left-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
  }

  .right-panel ul li:nth-child(2) {
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
  }

  .indust_title {
    height: 950px;
    margin: 0;
  }

  .project {
    text-align: center;
  }

  .middle {
    width: 100%;
    top: 44% !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .image {
    width: 512px !important;
    height: 539px !important;
  }

  .indust {
    margin: auto;
    text-align: center;
  }

  .indust_containt {
    padding: 0 !important;
    margin: 0 !important;
    display: contents;
  }

  .indust_containt p {
    padding: 0 !important;
    margin: 0 !important;
  }

  .main_circle {
    width: 300px;
    height: 300px;
  }

  .main_cirvc_crc {
    height: 239px;
  }

  .main_circle::after {
    width: 341px;
    height: 341px;
  }

  .home {
    /* height: 125vh; */
    padding-top: 50px;
  }

  .cl-button.-big {
    width: 100px;
  }

  .company {
    padding: 50px 0;
    margin: 0;
  }

  .language {
    height: 100%;
  }

  .h_service {
    padding: 50px 0;
    margin: 0;
  }

  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    display: none;
  }

  .main-timeline:before {
    display: none;
  }

  .main-timeline .timeline-content {
    width: 100%;
  }

  .main-timeline .timeline:before {
    display: none;
  }

  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(2n) .timeline-content:after {
    display: none;
  }

  .contact-links {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .contact-form-wrapper {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .circle {
    display: none;
  }

  .three {
    width: 100% !important;
    margin-top: 50px !important;
  }

  .laptop {
    width: 353px !important;
    height: 187px !important;
    margin: 0;
    padding: 0;
  }

  .laptop .content {
    width: 325px !important;
    height: 177px !important;
  }

  .laptop::after {
    width: 200px !important;
    height: 40px !important;
    margin: 0 0 0 59px !important
  }

  .smartphone {
    width: 375px;
    max-width: 100%;
    height: 700px;
  }

  .phone img {
    width: auto;
    height: 35.9rem;
    border-radius: 5px;
  }

  canvas {
    width: 340px;
  }

  .blog {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .firstimg {
    width: 300px;
    margin-left: 0px;
  }

  .secondimg {
    width: 201px;
    /* margin-left: 146px; */
    margin-top: -96px;
  }

  #home_services {
    display: none;
  }

  #b_page {
    display: none;
  }

  #h_service_mobile {
    display: contents;
  }

  #b_mobile {
    display: block;
  }

  #case_study {
    display: none;
  }

  #casestudy {
    display: contents;
  }

  /* .circles.circleOne {
    display: none;
  }

  .circles.circleTwo {
    display: none;
  }

  .circles.circleThree{
    display: none;
  }

  .circles.circleFour{
    display: none;
  } */

  /* .circles-container{
    color: #000 !important;
    top: 75% !important;
    word-wrap: break-word;
    width: 90px;
  } */

  .svg_imge {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  #demo {
    width: 90%;
    display: block;
    margin: auto;
  }

  /* .circles-container {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    top: 63.5% !important;
    left: 0 !important;
  } */

  /* .circles {
    width: 100px !important;
    height: 100px !important;
    top: 60% !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  } */

  /* .logo {
    color: #000 !important;
    word-wrap: break-word;
    width: 90px;
  } */
}

/* @media only screen and (min-width:280px) and (max-width: 320px) {
  #case_study {
    display: none;
  }

  #casestudy {
    display: contents;
  }

  .svg_imge {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .circles-container {
    align-items: center !important;
    text-align: center !important;
    vertical-align: middle !important;
    top: 67.1% !important;
    left: 0 !important;
  }

  .circles {
    width: 50px !important;
    height: 50px !important;
    top: 100% !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .logo {
    word-wrap: break-word;
    width: 90px;
    font-size: 12px !important;
  }
} */

/* -------------------- Loading -------------------- */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  background: #776161 0.5;
}

.spinner-container {
  position: absolute;
  display: grid;
  /* left: 50%; */
  width: 100%;
  background-color: #fffffff8;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card_id {
  padding: 8px;
  position: relative;
  margin: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  border-top: 6px solid #2f82b3;
  border-radius: 30px 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}



/* -------------------- Loading End  -------------------- */

/* @media only screen and (max-width:990px) {

.main-timeline .timeline {
  margin: 0 0 10px
  }

  .main-timeline .timeline-icon {
    left: 25%
  }

  .main-timeline .timeline:nth-child(even) .timeline-icon {
    right: 25%
  }

  .main-timeline .timeline-content {
    margin-top: 115px;
    padding: 0 43px;
  }

  .circle {
    display: none;
  }

  .three {
    width: 48% !important;
    margin-top: 50px !important;
  }

  canvas {
    width: 550px;
  }
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
} */

/* -------------------------------------- Button selection (Not uses) ---------------------------------------- */
/* ul.swatch-view {
  padding: 0px;
  list-style: none !important;
}

.swatch-view {
  margin: 10px 0px;
}

.swatch-view li {
  position: relative;
  display: inline-block;
  max-width: 100%;
  list-style: none !important;
  margin: 0.5rem 1rem 0.5rem 0;
  outline: none;
}

.star-set-btn {
  position: relative;
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  -webkit-appearance: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.swatches {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 100%;
  min-width: 100%;
  direction: ltr;
}

.swatch-item-unavailable::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 0;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1.5px solid;
  border-color: #6A6A6A;
  transform: rotate(45deg);
  margin: auto;
}

div.swatch-preset-558691 div.star-set-btn {
  border: 1px solid #CFCFCF;
}

div.swatch-preset-558691 div.star-set-btn {
  padding: 9px 10px;
  min-width: 35.6px;
  font-size: 13px;
  line-height: 15.6px;
} */
/* -------------------------------------- Button selection (Not uses) ---------------------------------------- */

/* Het Patel */
canvas {
  width: 100%;
}

.nav-pills .nav-link.active.tab-link-variation-2 {
  transform: none !important;
}

@media only screen and (min-width: 992px) and (max-width: 2000px) {
  #mobile {
    width: 13% !important;
  }

  .cl-button.-big {
    min-width: 170px;
    text-align: center;
    padding: 1rem 0.3rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  #mobile {
    width: 17% !important;
  }

  .cl-button.-big {
    min-width: 170px;
    text-align: center;
    padding: 1rem 0.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .h_service {
    display: none;
  }

  .h_service_mobile .service_inner li {
    float: none;
    margin: 1rem !important;
    width: auto;
  }

  .h_service_mobile .service_inner2 li {
    float: none;
    margin: 1rem !important;
  }

  .h_service_mobile .main_circle {
    margin: 1rem auto !important;
  }

  .h_service_mobile {
    display: block;
  }

  #b_mobile {
    display: block;
  }

  .middle {
    top: 50% !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .image {
    width: 512px !important;
    height: 539px !important;
  }
}

@media only screen and (max-width: 540px) {
  .firstimg {
    width: 300px;
    margin-left: 0px;
  }

  .secondimg {
    width: 201px;
    margin-left: 190px;
    margin-top: -96px;
  }
}

@media only screen and (max-width: 420px) {
  .firstimg {
    width: 300px;
    margin-left: 0px;
  }

  .secondimg {
    width: 200px;
    margin-left: 70px;
    margin-top: -96px;
  }

  .main_circle::after {
    width: 300px;
    height: 300px;
    top: -35px;
    left: -35px;
  }

  .main_circle {
    width: 280px;
    height: 280px;
  }

  .company {
    /* overflow-y: hidden; */
  }
}

@media only screen and (max-width: 320px) {
  .firstimg {
    width: 250px;
    margin-left: 0px;
  }

  .secondimg {
    width: 200px;
    margin-left: 40px;
    margin-top: -96px;
  }

  .main_circle::after {
    width: 270px;
    height: 270px;
    top: -35px;
    left: -35px;
  }

  .main_circle {
    width: 250px;
    height: 250px;
  }

  .tab-content .tab-pane {
    height: 100%;
  }

  .tab-content {
    padding-inline: 0;
  }

  .tab-content .tab-pane .image {
    height: 566px !important;
  }

  .middle {
    padding-inline: 10px !important;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    margin-top: 1rem;
  }

  .company {
    background-size: cover;
  }
}

/* services -> timeline fix start*/

@media only screen and (max-width: 1024px) {
  .main-timeline .timeline-icon {
    display: none;
  }

  .main-timeline .timeline-content {
    margin: 52px 0 0 0;
    width: 100%;
  }

  .main-timeline .timeline::before {
    content: none;
  }

  .main-timeline .timeline-content::before {
    content: none;

  }

  .main-timeline .timeline-content::after {
    content: none;
  }
}

@media only screen and (max-width: 320px) {
  .main-timeline .timeline-content {
    padding: 0 20px;
  }
}


/* services -> timeline fix end*/

/* solution fix start*/

@media screen and (max-width: 600px) {
  .laptop {
    width: 353px !important;
    height: 187px !important;
    margin: 0;
    padding: 0;
  }

  .laptop .content {
    width: 325px !important;
    height: 177px !important;
  }

  .laptop::after {
    width: 200px !important;
    height: 40px !important;
    margin: 0 0 0 59px !important
  }
}

@media screen and (max-width: 385px) {
  .laptop {
    width: 320px !important;
    height: 177px !important;
    margin: 0;
    padding: 0;
  }

  .laptop .content {
    width: 290px !important;
    height: 167px !important;
  }

  .laptop::after {
    width: 180px !important;
    height: 30px !important;
    margin: 0 0 0 59px !important
  }
}

@media screen and (max-width: 330px) {
  .laptop {
    width: 270px !important;
    height: 150px !important;
    margin: 0;
    padding: 0;
  }

  .laptop .content {
    width: 241px !important;
    height: 130px !important;
  }

  .laptop::after {
    width: 130px !important;
    height: 30px !important;
    margin: 0 0 0 59px !important
  }
}

@media screen and (max-width: 290px) {
  .laptop {
    width: 260px !important;
    height: 145px !important;
    margin: 0;
    padding: 0;
  }

  .laptop .content {
    width: 231px !important;
    height: 120px !important;
  }

  .laptop::after {
    width: 120px !important;
    height: 30px !important;
    margin: 0 0 0 59px !important
  }
}


/* solution fix end*/


/* ContactUs fix start */

@media screen and (max-width: 1000px) {

  .contact-links,
  .contact-form-wrapper {
    width: 45%;
  }

}

@media screen and (min-width: 1200px) {
  .card-align {
    height: 40rem !important;
  }
}

@media screen and (max-width: 768px) {

  .contact-links,
  .contact-form-wrapper {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
  }

  .contact-form-wrapper {
    padding-top: 30px;
  }
}

/* ContactUs fix end */


/* * {
  outline: 1px solid red;
  background-color: #ff000011;
} */