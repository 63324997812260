@import 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700i,900i';

$green: #30b2e6;
$purple: #3b3d54;

.wrap{
	perspective-origin: 50% 50%;
}


.svg{
	stroke:$green;
	position:absolute;
	bottom: -100vh;
	transform-style: preserve-3d;
}

$total: 10;
@for $i from 1 through $total {
	$scale:random(2) - .4;
	
	.svg:nth-child(#{$i}){
		left:random(100) * 1% - 20;
		animation: raise#{$i} 6 + random(15) +s linear infinite;
		animation-delay:random(5) - 5 + s;
		transform: scale(.3 * $i - .6) rotate(random(360) + deg);
		z-index: $i - 7;
		filter: blur($i - 6 + px);

	  @keyframes raise#{$i} {
		  to{
			 bottom:150vh;
			 transform: scale(.3 * $i - .6) rotate(random(360) + deg);
			}
		}
	}
}